import { useSelector } from 'react-redux';
import { constants, selectors } from '@yola/subscription-manager-js';

const {
  SubscriptionTerm: { MONTHLY, ANNUAL },
} = constants;

const useSpecialOffer = ({
  bundledSubscriptionType,
  extraPriceType,
  activeHostingSubscription,
}) => {
  const mostPopularHostingPlan = useSelector(selectors.getMostPopularHostingPackage);
  const hostingPackages = useSelector(selectors.getHostingPackages);
  const lowestAvailablePaidHostingPlan = useSelector(
    selectors.getLowestAvailablePaidHostingPackage
  );

  if (!extraPriceType) {
    return null;
  }

  if (bundledSubscriptionType) {
    return {
      bundled: true,
      subscriptionName: hostingPackages[bundledSubscriptionType].name,
      subscriptionType: bundledSubscriptionType,
      disabled: true,
    };
  }

  if (!activeHostingSubscription) {
    return {
      bundled: true,
      subscriptionName: hostingPackages[mostPopularHostingPlan.type].name,
      subscriptionType: mostPopularHostingPlan.type,
      disabled: false,
    };
  }

  // user has active monthly bronze subscription
  if (
    activeHostingSubscription.term === MONTHLY &&
    activeHostingSubscription.type === lowestAvailablePaidHostingPlan.type
  ) {
    return {
      bundled: true,
      subscriptionName: hostingPackages[mostPopularHostingPlan.type].name,
      subscriptionType: mostPopularHostingPlan.type,
      disabled: false,
    };
  }

  return {
    bundled: activeHostingSubscription.term === MONTHLY, // `bundled` if user has monthly subscription
    subscriptionName: hostingPackages[activeHostingSubscription.type].name,
    subscriptionType: activeHostingSubscription.type,
    disabled: activeHostingSubscription.term === ANNUAL, // `disabled` if user has annual subscription
  };
};

export default useSpecialOffer;
