import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import fieldNames from '../../../constants/form-field-names';

const { InputGroupField, SearchableSelectField } = designSystem;

const StateField = ({
  country,
  rules,
  options,
  noResultsText,
  disabled,
  control,
  setValue,
  placeholder,
}) => {
  const targetOptions = options[country] || [];
  const isSelectField = !!targetOptions.length;

  useEffect(() => {
    if (isSelectField) {
      setValue(fieldNames.STATE, '', { shouldValidate: false });
    }
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [country]);

  if (isSelectField) {
    return (
      <SearchableSelectField
        name={fieldNames.STATE}
        rules={rules}
        control={control}
        options={targetOptions}
        noResultsText={noResultsText}
        placeholder={placeholder}
        disabled={disabled}
      />
    );
  }

  return (
    <InputGroupField name={fieldNames.STATE} rules={rules} control={control} disabled={disabled} />
  );
};

StateField.propTypes = {
  country: PropTypes.string,
  rules: PropTypes.shape({}),
  options: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    )
  ).isRequired,
  placeholder: PropTypes.string,
  noResultsText: PropTypes.string,
  disabled: PropTypes.bool,
  control: PropTypes.shape({}).isRequired,
  setValue: PropTypes.func,
};

StateField.defaultProps = {
  country: '',
  rules: {},
  noResultsText: '',
  placeholder: '',
  disabled: false,
  setValue: Function.prototype,
};

export default StateField;
