import React from 'react';
import PropTypes from 'prop-types';
import { B2CDomainSelector } from '@yola/webapp-plugins';

function DomainSelector(props) {
  const {
    domainName,
    currency,
    captionsOverrides,
    onSelect,
    onDomainSearchFailure,
    onClose,
    onNext,
    onSearchStart,
    onSearchPerformed,
    onDomainFieldChange,
    additionalPaymentParams,
    extraPrice,
    specialOffer,
    activeSubscriptionTerm,
    partnerName,
    onSpecialOfferSwitch,
  } = props;

  return (
    <div className="ws-domain-selector">
      <B2CDomainSelector
        view="content"
        domainName={domainName}
        currency={currency}
        captionsOverrides={captionsOverrides}
        onSelect={onSelect}
        onDomainSearchFailure={onDomainSearchFailure}
        onClose={onClose}
        onNext={onNext}
        onSearchStart={onSearchStart}
        onSearchPerformed={onSearchPerformed}
        onDomainFieldChange={onDomainFieldChange}
        onSpecialOfferSwitch={onSpecialOfferSwitch}
        additionalPaymentParams={additionalPaymentParams}
        extraPrice={extraPrice}
        specialOffer={specialOffer}
        activeSubscriptionTerm={activeSubscriptionTerm}
        partnerName={partnerName}
      />
    </div>
  );
}

DomainSelector.propTypes = {
  domainName: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  captionsOverrides: PropTypes.shape().isRequired,
  onSelect: PropTypes.func.isRequired,
  onDomainSearchFailure: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onNext: PropTypes.func,
  onSearchStart: PropTypes.func.isRequired,
  onSearchPerformed: PropTypes.func.isRequired,
  onDomainFieldChange: PropTypes.func.isRequired,
  additionalPaymentParams: PropTypes.shape().isRequired,
  extraPrice: PropTypes.string,
  specialOffer: PropTypes.oneOfType([
    PropTypes.shape({
      bundled: PropTypes.bool,
      subscriptionName: PropTypes.string,
      subscriptionType: PropTypes.string,
      disabled: PropTypes.bool,
    }),
    PropTypes.oneOf([null]),
  ]),
  onSpecialOfferSwitch: PropTypes.func,
  partnerName: PropTypes.string.isRequired,
  activeSubscriptionTerm: PropTypes.string,
};

DomainSelector.defaultProps = {
  specialOffer: null,
  extraPrice: '',
  onNext: null,
  activeSubscriptionTerm: '',
  onSpecialOfferSwitch: Function.prototype,
};

export default DomainSelector;
