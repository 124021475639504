import { constants } from '@yola/subscription-manager-js';
import { premiumFeatures } from '../../common/constants/premium-features';

const { hostingPackageFeatureNames } = constants;

const getHighlightedHostingPackageFeatures = (premiumFeature, hasDomainExtraPrice) =>
  ({
    [premiumFeatures.CUSTOM_DOMAIN]: [
      { name: hostingPackageFeatureNames.CUSTOM_DOMAIN, position: 2 },
      hasDomainExtraPrice && { name: hostingPackageFeatureNames.FREE_DOMAIN_PROMO, position: 3 },
    ].filter(Boolean),
  })[premiumFeature] || null;

export default getHighlightedHostingPackageFeatures;
