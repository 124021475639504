import React from 'react';
import { useHistory } from 'react-router-dom';
import { premiumFeatures } from '../../modules/common/constants/premium-features';
import segment from '../../modules/analytics/segment';
import DomainSelectorContainer from '../../modules/domain-selector/containers/domain-selector-container';
import useSupportedSearchParams from '../hooks/use-supported-search-params';
import routesMap from '../helpers/routes-map';

const {
  constants: { triggerIds },
} = segment;

function DomainSelectorRoute() {
  const history = useHistory();
  const searchParams = useSupportedSearchParams();

  if (!searchParams) return null;

  const { initiator, prefill, products = [], term, triggerId, back, paymentBackUrl } = searchParams;
  const hasProducts = Boolean(products.length);

  const showPaywall = (domain) => {
    history.push(
      routesMap.paywall.url({
        query: {
          domain,
          premiumFeature: premiumFeatures.CUSTOM_DOMAIN,
          triggerId: triggerIds.DOMAIN_SELECTOR,
          ...(hasProducts && { products }),
          ...(paymentBackUrl && { paymentBackUrl }),
        },
      })
    );
  };

  const propsFromSearchParams = {
    ...(initiator && { initiator }),
    ...(prefill && { prefill }),
    ...(hasProducts && { extraProducts: products }),
    ...(term && { term }),
    ...(triggerId && { triggerId }),
    ...(back && { backUrl: back }),
    ...(paymentBackUrl && { paymentBackUrl }),
  };

  return <DomainSelectorContainer showPaywall={showPaywall} {...propsFromSearchParams} />;
}

export default DomainSelectorRoute;
