import React, { useMemo, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, designSystem, reactHookForm } from '@yola/ws-ui';
import i18next from 'i18next';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import dialogs from '../../../dialogs';
import StateField from './components/state-field';
import getCountries from '../../../../common/helpers/get-countries';
import getStates from '../../../../common/helpers/get-states';
import { formDataPropTypes } from './constants';
import fieldNames from '../../constants/form-field-names';
import formatCreditCardNumber from './helpers/format-credit-card-number';
import formatCardExpirationDate from './helpers/format-card-expiration-date';
import formatCardCSC from './helpers/format-card-csc';
import processServiceValidationErrors from './helpers/process-service-validation-errors';
import status from '../../../status';
import errorCodes from '../../constants/error-codes';
import ErrorNotificationContainer from '../../../../common/containers/error-notification-container';
import StripeContainer from '../../../stripe/containers/stripe-container';
import errorSource from '../../../stripe/constants/error-source';

const {
  DialogHeader,
  DialogHeaderTitle,
  DialogHeaderControls,
  DialogHeaderIconButton,
  ControlGroup,
  Divider,
  ActionButton,
  ModalButtonGroup,
  Box,
  Stack,
  Heading,
  InputGroupField,
  CheckboxField,
  SearchableSelectField,
  Loader,
} = designSystem;
const { useForm } = reactHookForm;

const MODAL_WIDTH = 600;
const overlaySpacing = { y: 50 };

const processFormattedDate = (date) => {
  const [month, yearLastDigits] = date.split('/');
  const year = `20${yearLastDigits}`;
  return [month, year];
};

const getCaptions = (captionsOverrides) => ({
  title: i18next.t('Add payment method'),
  secureLabel: i18next.t('Save & secure payment'),
  defaultServiceError: i18next.t(
    "Something went wrong and we couldn't save your payment method. Please double-check your details and try again."
  ),
  alreadyExistServiceError: i18next.t(
    "Something went wrong and we couldn't save your payment method. Billing profile already exists."
  ),
  [fieldNames.CARD_NUMBER]: {
    label: i18next.t('Card details'),
    validationErrors: {
      required: i18next.t('Card details is required'),
      maxLength: i18next.t('Enter a valid card number'),
      minLength: i18next.t('Enter a valid card number'),
    },
  },
  [fieldNames.EXPIRATION_DATE]: {
    label: i18next.t('Expiration date'),
    validationErrors: {
      pattern: i18next.t('Enter a valid date'),
      validate: i18next.t('Expiration date is in past'),
    },
  },
  [fieldNames.VERIFICATION]: {
    label: i18next.t('Security code'),
    validationErrors: {
      required: i18next.t('Security code is required'),
      minLength: i18next.t('Valid CSC is required'),
      maxLength: i18next.t('Valid CSC is required'),
    },
  },
  [fieldNames.NAME]: {
    label: i18next.t('Name on card'),
    placeholder: i18next.t('First and Last name'),
    validationErrors: {
      required: i18next.t('Name on card is required'),
    },
  },
  [fieldNames.COUNTRY_CODE]: {
    label: i18next.t('Country'),
    noResultsText: i18next.t('No results'),
  },
  [fieldNames.STATE]: {
    label: i18next.t('State'),
    noResultsText: i18next.t('No results'),
  },
  [fieldNames.CITY]: {
    label: i18next.t('City'),
    validationErrors: {
      required: i18next.t('City is required'),
    },
  },
  [fieldNames.ADDRESS1]: {
    label: i18next.t('Address'),
    validationErrors: {
      required: i18next.t('Street address is required'),
    },
  },
  [fieldNames.POSTAL_CODE]: {
    label: i18next.t('Zip'),
    validationErrors: {
      required: i18next.t('Zip is required'),
    },
  },
  [fieldNames.MAKE_DEFAULT]: {
    label: i18next.t('Make this payment method my default'),
  },
  saveButton: i18next.t('Save'),
  cancelButton: i18next.t('Cancel'),
  validationErrors: {
    required: i18next.t('The field is required'),
    invalid: i18next.t('The field is invalid'),
  },
  ...captionsOverrides,
});

const PaymentMethodFormDialog = (props) => {
  const {
    formData,
    captions: captionsOverrides,
    onSave,
    onCancel,
    disabledFields,
    statusName,
  } = props;
  const [serviceError, setServiceError] = useState(false);
  const [serviceErrorMessage, setServiceErrorMessage] = useState('');
  const [isStripeRequestPending, setStripeRequestPending] = useState(false);
  const hasStripeForm = !formData.cardNumber;
  const [isFormLoading, setIsFormLoading] = useState(hasStripeForm);
  const targetStatus = status.hooks.useStatus(statusName);
  const isLoading = targetStatus === status.constants.LOADING || isStripeRequestPending;
  const stripeFormRef = useRef(null);
  const captions = useMemo(() => getCaptions(captionsOverrides), [captionsOverrides]);
  const dispatch = useDispatch();

  const countryOptions = useMemo(
    () =>
      Object.entries(getCountries()).map(([value, { name }]) => ({
        value: value.toUpperCase(),
        label: name,
      })),
    []
  );

  const stateOptions = useMemo(
    () =>
      Object.entries(getStates()).reduce((acc, [country, targetStates]) => {
        acc[country] = Object.entries(targetStates).map(([value, label]) => ({ value, label }));
        return acc;
      }, {}),
    []
  );

  const validateExpirationDate = useCallback(
    (value) => {
      const [expirationMonth, expirationYear] = processFormattedDate(value);
      const currentDate = new Date(Date.now());
      const expirationDate = new Date(expirationYear, expirationMonth);
      const remainingTime = expirationDate.getTime() - currentDate.getTime();

      if (remainingTime <= 0) {
        return captions[fieldNames.EXPIRATION_DATE].validationErrors.validate;
      }

      return true;
    },
    [captions]
  );

  const expirationDateRules = useMemo(
    () => ({
      required: captions.validationErrors.required,
      pattern: {
        value: /^(0[1-9]|1[0-2])\/?([0-9]{2})$/,
        message: captions[fieldNames.EXPIRATION_DATE].validationErrors.pattern,
      },
      validate: validateExpirationDate,
    }),
    [validateExpirationDate, captions]
  );

  const nameRules = useMemo(
    () => ({
      required: captions[fieldNames.NAME].validationErrors.required,
      maxLength: { value: 81, message: captions.validationErrors.invalid },
    }),
    [captions]
  );

  const countryCodeRules = useMemo(
    () => ({ required: captions.validationErrors.required }),
    [captions]
  );

  const stateRules = useMemo(
    () => ({
      required: captions.validationErrors.required,
      maxLength: { value: 40, message: captions.validationErrors.invalid },
    }),
    [captions]
  );

  const cityRules = useMemo(
    () => ({
      required: captions[fieldNames.CITY].validationErrors.required,
      maxLength: { value: 40, message: captions.validationErrors.invalid },
    }),
    [captions]
  );

  const address1Rules = useMemo(
    () => ({
      required: captions[fieldNames.ADDRESS1].validationErrors.required,
      pattern: { value: /[a-zA-Z0-9.,/#@+&-]/, message: captions.validationErrors.invalid },
      maxLength: { value: 255, message: captions.validationErrors.invalid },
    }),
    [captions]
  );

  const postalCodeRules = useMemo(
    () => ({
      required: captions[fieldNames.POSTAL_CODE].validationErrors.required,
      maxLength: { value: 15, message: captions.validationErrors.invalid },
    }),
    [captions]
  );

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors: formErrors },
  } = useForm({
    defaultValues: formData,
  });
  const watchCountryCode = watch(fieldNames.COUNTRY_CODE);

  const formatValueOnChange = (fieldName, valueFormatter) => (value) => {
    const formattedValue = valueFormatter(value);
    setValue(fieldName, formattedValue, {
      shouldValidate: !!formErrors[fieldName],
    });
  };

  const onCountryChange = () => {
    setValue(fieldNames.STATE, '', { shouldValidate: false });
  };

  const handleServiceError = (err = {}) => {
    const { code = errorCodes.UNKNOWN_ERROR, fields } = err;
    let message = '';
    let errorData = {};

    switch (code) {
      case errorCodes.BILLING_PROFILE_ALREADY_EXISTS:
        message = captions.alreadyExistServiceError;
        break;
      case errorCodes.FIELD_VALIDATION_FAILURE: {
        message = captions.defaultServiceError;
        errorData = { fields };
        break;
      }
      case errorCodes.INCORECT_BILLING_DETAILS:
        message = captions.defaultServiceError;
        break;
      default:
        message = '';
    }

    return { code, message, data: errorData };
  };

  const handleSave = async (data) => {
    const {
      expirationDate,
      cardNumber,
      countryCode,
      state,
      city,
      address1,
      postalCode,
      name,
      verification,
      ...restData
    } = data;

    const billingInformation = {
      countryCode,
      state,
      city,
      address1,
      postalCode,
      name,
    };

    setServiceError(false);
    setServiceErrorMessage('');

    let processedData = {};

    if (!hasStripeForm) {
      const [expirationMonth, expirationYear] = processFormattedDate(expirationDate);

      processedData = {
        expirationMonth,
        expirationYear,
        ...billingInformation,
      };
    } else {
      try {
        setStripeRequestPending(true);

        const result = await stripeFormRef.current.submit({
          country: billingInformation.countryCode,
          state: billingInformation.state,
          city: billingInformation.city,
          line1: billingInformation.address1,
          name: billingInformation.name,
          postalCode: billingInformation.postalCode,
        });

        setStripeRequestPending(false);

        if (result.error) {
          if (result.source !== errorSource.CLIENT) {
            throw result;
          }

          return;
        }

        const { paymentMethod } = result;

        processedData = {
          paymentMethodId: paymentMethod.id,
          ...billingInformation,
        };
      } catch (e) {
        setServiceError(true);
        setServiceErrorMessage(e.error ? e.error.message : captions.defaultServiceError);

        return;
      }
    }

    try {
      await onSave({
        ...restData,
        ...processedData,
      });
    } catch (e) {
      const { code, message = '', data: errorData } = handleServiceError(e);

      setServiceError(true);

      if (code === errorCodes.UNKNOWN_ERROR) {
        return;
      }

      setServiceErrorMessage(message);

      if (errorData && errorData.fields) {
        const errors = processServiceValidationErrors(
          errorData.fields,
          captions.validationErrors.invalid
        );

        Object.entries(errors).forEach(([fieldName, errorMessage]) => {
          setError(fieldName, { type: 'custom', message: errorMessage });
        });
      }
    }
  };

  const submit = (...args) => {
    if (hasStripeForm) {
      stripeFormRef.current.validate();
    }

    handleSubmit(handleSave)(...args);
  };

  return (
    <Modal
      isContainerScrollable
      isBodyScrollDisabled
      width={MODAL_WIDTH}
      maxWidth={MODAL_WIDTH}
      minWidth={MODAL_WIDTH}
      overlaySpacing={overlaySpacing}
      height="auto"
      centered
      resizable={false}
      draggable={false}
      overlay="visible"
      className="ws-account-management-modal"
    >
      <DialogHeader>
        <DialogHeaderTitle>{captions.title}</DialogHeaderTitle>
        <DialogHeaderControls>
          <DialogHeaderIconButton onClick={onCancel} disabled={isLoading} glyph="close" />
        </DialogHeaderControls>
      </DialogHeader>
      <Divider />
      <form onSubmit={submit} className="ws-payment-method-form__form">
        {isFormLoading && (
          <div className="ws-payment-method-form__loader">
            <Loader />
          </div>
        )}
        <Box
          marginTop="spacing-s"
          marginRight="spacing-m"
          marginBottom="spacing-s"
          marginLeft="spacing-m"
        >
          <Stack gap="spacing-m">
            {!formData.cardNumber && (
              <div
                className={classNames({
                  'ws-payment-method-form__stripe-placeholder': isFormLoading,
                })}
              >
                <Stack gap="spacing-m">
                  {serviceError && (
                    <ErrorNotificationContainer
                      title={serviceErrorMessage}
                      disableTracking={Boolean(serviceErrorMessage)}
                    />
                  )}

                  <StripeContainer
                    ref={stripeFormRef}
                    onLoaderror={() => {
                      dispatch(dialogs.actions.show(dialogs.dialogTypes.STRIPE_LOADING_ERROR));
                    }}
                    onReady={() => {
                      // we wait additional time until stripe fields placeholder is hidden
                      // and the size of stripe iframe is calculated
                      setTimeout(() => {
                        setIsFormLoading(false);
                      }, 1000);
                    }}
                  />
                </Stack>
              </div>
            )}

            {formData.cardNumber && (
              <React.Fragment>
                {serviceError && (
                  <ErrorNotificationContainer
                    title={serviceErrorMessage}
                    disableTracking={Boolean(serviceErrorMessage)}
                  />
                )}
                <ControlGroup title={captions[fieldNames.CARD_NUMBER].label}>
                  <InputGroupField
                    placeholder="1234 1234 1234 1234"
                    name={fieldNames.CARD_NUMBER}
                    control={control}
                    disabled={disabledFields.includes(fieldNames.CARD_NUMBER)}
                    onChange={formatValueOnChange(fieldNames.CARD_NUMBER, formatCreditCardNumber)}
                  />
                </ControlGroup>
                <div className="ws-payment-method-form__field-row">
                  <ControlGroup title={captions[fieldNames.EXPIRATION_DATE].label}>
                    <InputGroupField
                      placeholder="MM/YY"
                      name={fieldNames.EXPIRATION_DATE}
                      rules={expirationDateRules}
                      control={control}
                      disabled={disabledFields.includes(fieldNames.EXPIRATION_DATE)}
                      onChange={formatValueOnChange(
                        fieldNames.EXPIRATION_DATE,
                        formatCardExpirationDate
                      )}
                    />
                  </ControlGroup>
                  <ControlGroup>
                    <div className="ws-payment-method-form__security-code-title">
                      <Heading type="heading-6">{captions[fieldNames.VERIFICATION].label}</Heading>
                    </div>
                    <div className="ws-payment-method-form__security-code-field">
                      <div className="ws-payment-method-form__security-code-input">
                        <InputGroupField
                          type="password"
                          placeholder="CSC"
                          name={fieldNames.VERIFICATION}
                          control={control}
                          disabled={disabledFields.includes(fieldNames.VERIFICATION)}
                          onChange={formatValueOnChange(fieldNames.VERIFICATION, formatCardCSC)}
                        />
                      </div>
                      <img
                        className="ws-site-tour__img"
                        src="/static/js-app/images/credit-card.png"
                        alt=""
                      />
                    </div>
                  </ControlGroup>
                </div>
              </React.Fragment>
            )}

            <ControlGroup title={captions[fieldNames.NAME].label}>
              <InputGroupField
                placeholder={captions[fieldNames.NAME].placeholder}
                name={fieldNames.NAME}
                rules={nameRules}
                control={control}
                disabled={disabledFields.includes(fieldNames.NAME)}
              />
            </ControlGroup>
            <Divider />
            <ControlGroup title={captions[fieldNames.COUNTRY_CODE].label}>
              <SearchableSelectField
                name={fieldNames.COUNTRY_CODE}
                options={countryOptions}
                noResultsText={captions[fieldNames.COUNTRY_CODE].noResultsText}
                rules={countryCodeRules}
                control={control}
                disabled={disabledFields.includes(fieldNames.COUNTRY_CODE)}
                onChange={onCountryChange}
              />
            </ControlGroup>
            <div className="ws-payment-method-form__field-row">
              <ControlGroup title={captions[fieldNames.STATE].label}>
                <StateField
                  key={watchCountryCode}
                  country={watchCountryCode}
                  options={stateOptions}
                  noResultsText={captions[fieldNames.STATE].noResultsText}
                  placeholder={captions[fieldNames.STATE].label}
                  rules={stateRules}
                  control={control}
                  disabled={disabledFields.includes(fieldNames.STATE)}
                  setValue={setValue}
                />
              </ControlGroup>
              <ControlGroup title={captions[fieldNames.CITY].label}>
                <InputGroupField
                  name={fieldNames.CITY}
                  rules={cityRules}
                  control={control}
                  disabled={disabledFields.includes(fieldNames.CITY)}
                />
              </ControlGroup>
            </div>
            <div className="ws-payment-method-form__field-row">
              <ControlGroup title={captions[fieldNames.ADDRESS1].label}>
                <InputGroupField
                  name={fieldNames.ADDRESS1}
                  rules={address1Rules}
                  control={control}
                  disabled={disabledFields.includes(fieldNames.ADDRESS1)}
                />
              </ControlGroup>
              <ControlGroup title={captions[fieldNames.POSTAL_CODE].label}>
                <InputGroupField
                  name={fieldNames.POSTAL_CODE}
                  rules={postalCodeRules}
                  control={control}
                  disabled={disabledFields.includes(fieldNames.POSTAL_CODE)}
                />
              </ControlGroup>
            </div>
            <CheckboxField
              label={captions[fieldNames.MAKE_DEFAULT].label}
              name={fieldNames.MAKE_DEFAULT}
              control={control}
              disabled={disabledFields.includes(fieldNames.MAKE_DEFAULT)}
            />
          </Stack>
        </Box>
        <ModalButtonGroup direction="right">
          <ActionButton
            appearance="accent"
            format="solid"
            iconGlyph="lock"
            label={captions.saveButton}
            onClick={submit}
            isLoading={isLoading}
          />
          <ActionButton label={captions.cancelButton} disabled={isLoading} onClick={onCancel} />
        </ModalButtonGroup>
      </form>
    </Modal>
  );
};

PaymentMethodFormDialog.propTypes = {
  formData: formDataPropTypes.isRequired,
  captions: PropTypes.shape({
    title: PropTypes.string,
    secureLabel: PropTypes.string,
    [fieldNames.CARD_NUMBER]: PropTypes.shape({
      label: PropTypes.string,
      validationErrors: PropTypes.shape(),
    }),
    [fieldNames.EXPIRATION_DATE]: PropTypes.shape({
      label: PropTypes.string,
      validationErrors: PropTypes.shape(),
    }),
    [fieldNames.VERIFICATION]: PropTypes.shape({
      label: PropTypes.string,
      tooltip: PropTypes.string,
      validationErrors: PropTypes.shape(),
    }),
    [fieldNames.NAME]: PropTypes.shape({
      label: PropTypes.string,
      placeholder: PropTypes.string,
      validationErrors: PropTypes.shape(),
    }),
    [fieldNames.COUNTRY_CODE]: PropTypes.shape({
      label: PropTypes.string,
      noResultsText: PropTypes.string,
      validationErrors: PropTypes.shape(),
    }),
    [fieldNames.STATE]: PropTypes.shape({
      label: PropTypes.string,
      noResultsText: PropTypes.string,
      validationErrors: PropTypes.shape(),
    }),
    [fieldNames.CITY]: PropTypes.shape({
      label: PropTypes.string,
      validationErrors: PropTypes.shape(),
    }),
    [fieldNames.ADDRESS1]: PropTypes.shape({
      label: PropTypes.string,
      validationErrors: PropTypes.shape(),
    }),
    [fieldNames.POSTAL_CODE]: PropTypes.shape({
      label: PropTypes.string,
      validationErrors: PropTypes.shape(),
    }),
    [fieldNames.MAKE_DEFAULT]: PropTypes.shape({
      label: PropTypes.string,
    }),
    saveButton: PropTypes.string,
    cancelButton: PropTypes.string,
    validationErrors: PropTypes.shape({
      required: PropTypes.string,
      invalid: PropTypes.string,
    }),
  }),
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  disabledFields: PropTypes.arrayOf(PropTypes.string),
  statusName: PropTypes.string.isRequired,
};

PaymentMethodFormDialog.defaultProps = {
  captions: {},
  disabledFields: [],
};

export default PaymentMethodFormDialog;
