import { useSelector, shallowEqual } from 'react-redux';
import { selectors, verifiers } from '@yola/subscription-manager-js';
import extraPrices from '../constants/extra-price-types';
import useFeatureFlags from '../../feature-flags/hooks/use-feature-flags';
import useAvailablePlatformComponents from '../../user/hooks/use-available-platform-components';
import platformComponents from '../../user/constants/platform-components';

function useDomainsExtraPrice() {
  const subscriptionsList = useSelector(selectors.getSubscriptionList, shallowEqual);
  const activeSubscriptions = useSelector(selectors.getActiveSubscriptions, shallowEqual);

  const [featureFlags] = useFeatureFlags(['freedomain']);
  const availablePlatformComponents = useAvailablePlatformComponents();

  const { freedomain: isFreeDomainFlagEnabled } = featureFlags;
  const isDomainsPlatformAvailable = availablePlatformComponents.includes(
    platformComponents.DOMAINS
  );

  if (
    !subscriptionsList ||
    !activeSubscriptions ||
    !isDomainsPlatformAvailable ||
    !isFreeDomainFlagEnabled
  )
    return null;

  const hasEverHadDomainSubscription = subscriptionsList.some(({ type }) =>
    verifiers.isDomainPackageType(type)
  );

  if (hasEverHadDomainSubscription) {
    return null;
  }

  const hasAnnualPaidHostingSubscription =
    verifiers.hasAnnualPaidHostingSubscription(activeSubscriptions);

  return hasAnnualPaidHostingSubscription ? extraPrices.NO_DISCOUNTS : extraPrices.FREE_COM_DOMAIN;
}

export default useDomainsExtraPrice;
