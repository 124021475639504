import React, { useMemo, useEffect, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { constants, selectors, verifiers } from '@yola/subscription-manager-js';
import i18next from 'i18next';
import redirectToUrl from 'src/js/utils/redirect-to-url';
import user from '../../user';
import dialogs from '../../dialogs';
import config from '../../config';
import segment from '../../analytics/segment';
import { DEFAULT_INITIATOR } from '../../common/constants';
import useDomainsExtraPrice from '../hooks/use-domains-extra-price';
import useSpecialOffer from '../hooks/use-special-offer';
import convertToValidDomainName from '../helpers/convert-to-valid-domain-name';
import DomainSelector from '../components/domain-selector';
import utils from '../../webapp-plugins/utils';
import actionIds from '../constants/action-ids';
import useFeatureFlags from '../../feature-flags/hooks/use-feature-flags';

const {
  constants: { events },
  track,
  trackAsync,
} = segment;

const {
  hostingPackageFeatureNames,
  SubscriptionTerm: { MONTHLY, ANNUAL },
} = constants;

function DomainSelectorContainer({
  initiator,
  prefill,
  extraProducts,
  term,
  triggerId,
  showPaywall,
  paymentBackUrl: overridePaymentBackUrl,
}) {
  const baseUrl = useSelector(config.selectors.getMyyolaUrl);
  const { currency } = useSelector(user.selectors.getUserPreferences);
  const isB2C = useSelector(user.selectors.getIsB2C);
  const partnerName = useSelector(user.selectors.getPartnerName);
  const currentPlan = useSelector(selectors.getCurrentPackage, shallowEqual);
  const packageType = useSelector(selectors.getCurrentPackageType);
  const activeHostingSubscription = useSelector(selectors.getActiveHostingSubscription);

  const dispatch = useDispatch();
  const onBackButtonClick = useCallback(() => {
    window.history.back();
  }, []);

  const [{ freedomain: isFreeDomainFlagEnabled }] = useFeatureFlags(['freedomain']);

  const hasExtraProducts = Boolean(extraProducts.length);
  const isPaidHostingProduct =
    hasExtraProducts && verifiers.hasPaidHostingPackageType(extraProducts);
  const isCustomDomainFeatureAvailable =
    currentPlan?.features?.[hostingPackageFeatureNames.CUSTOM_DOMAIN];
  const paymentBackUrl = overridePaymentBackUrl || baseUrl;
  const bundledSubscriptionType = extraProducts.find(verifiers.isPaidHostingPackageType);

  const captions = useMemo(
    () => ({
      closeButton: i18next.t('Back'),
    }),
    []
  );

  const additionalPaymentParams = useMemo(
    () => ({
      initiator: initiator || DEFAULT_INITIATOR,
      ...(hasExtraProducts && { products: extraProducts }),
      ...(term && { term }),
      p1y_upgrade: term === ANNUAL && activeHostingSubscription?.term === MONTHLY,
      backUrl: paymentBackUrl,
      successUrl: paymentBackUrl,
      failUrl: paymentBackUrl,
      closeUrl: paymentBackUrl,
    }),
    [initiator, hasExtraProducts, extraProducts, term, paymentBackUrl, activeHostingSubscription]
  );

  const extraPriceType = useDomainsExtraPrice(isPaidHostingProduct);
  const trackingData = {
    siteId: null,
    templateBuildSlug: null,
    ...(triggerId && { triggerId }),
  };

  useEffect(() => {
    track(events.DOMAIN_PURCHASE_DIALOG_DISPLAYED, trackingData);
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  const handleSelect = async ({
    paymentUrl,
    domain,
    searchString,
    bundledSubscriptionType: bundledSubscription,
  }) => {
    const indexOfDomainTypeSeparator = domain.indexOf('.');
    const domainNameSelected = domain.substring(0, indexOfDomainTypeSeparator);
    const domainTypeSelected = domain.substring(indexOfDomainTypeSeparator + 1);

    await trackAsync(events.DOMAIN_PURCHASE_CLICKED, {
      ...trackingData,
      domainName: searchString,
      domainNameSelected,
      domainTypeSelected,
      actionId: actionIds.BUY_DOMAIN,
      activeHostingSubscription: packageType,
    });

    if (isPaidHostingProduct || isCustomDomainFeatureAvailable || bundledSubscription) {
      dispatch(dialogs.actions.show(dialogs.dialogTypes.PENDING));
      redirectToUrl(paymentUrl);
    } else {
      showPaywall(domain);
    }
  };

  const handleDomainSearchFailure = () => {
    track(events.DOMAIN_SEARCH_FAILED, trackingData);
    dispatch(dialogs.actions.show(dialogs.dialogTypes.DOMAIN_SEARCH_FAILURE));
  };

  const handleClose = async () => {
    await trackAsync(events.DOMAIN_PURCHASE_DIALOG_CANCELED, trackingData);
    dispatch(dialogs.actions.hide());
    onBackButtonClick();
  };

  const handleNext = async () => {
    dispatch(dialogs.actions.show(dialogs.dialogTypes.PENDING));
    await trackAsync(events.DOMAIN_PURCHASE_NOT_NOW_CLICKED, trackingData);
    redirectToUrl(utils.generatePaymentUrl(additionalPaymentParams, isB2C));
  };

  const handleSearchStart = ({ searchString, domainsRequestOptions }) => {
    if (domainsRequestOptions && domainsRequestOptions.userAction) {
      track(events.DOMAIN_PURCHASE_SEARCH_CLICKED, {
        ...trackingData,
        domainName: searchString,
      });
    }
  };

  const handleSearchPerformed = ({ searchString, searchResults, domainsRequestOptions }) => {
    const availableDomains = searchResults.filter(({ available }) => available);
    const isSearchedDomainAvailable = availableDomains.some(({ domain }) => {
      const domainName = domain.substring(0, domain.indexOf('.'));
      return searchString === domain || searchString === domainName;
    });
    const suggestedDomainsAvailable = availableDomains.length;
    const suggestedDomainsTaken = searchResults.length - availableDomains.length;

    track(events.DOMAIN_PURCHASE_SEARCH_PERFORMED, {
      ...trackingData,
      domainName: searchString,
      isSearchedDomainAvailable,
      suggestedDomainsAvailable,
      suggestedDomainsTaken,
      searchClicked: Boolean(domainsRequestOptions && domainsRequestOptions.userAction),
    });
  };

  const handleDomainFieldChange = (option) => {
    if (option && option.tld) {
      track(events.DOMAIN_PURCHASE_AUTOCOMPLETE_DOMAIN_SELECTED, {
        ...trackingData,
        domainName: option.value,
        tld: option.tld,
      });
    }
  };

  const onSpecialOfferSwitch = (enabled) => {
    track(events.DOMAIN_PURCHASE_SPECIAL_OFFER_SWITCHED, {
      siteId: null,
      templateBuildSlug: null,
      enabled,
    });
  };

  const specialOffer = useSpecialOffer({
    bundledSubscriptionType,
    extraPriceType,
    activeHostingSubscription,
  });

  // don't show the domain selector if the flag is not loaded
  if (typeof isFreeDomainFlagEnabled !== 'boolean') {
    return null;
  }

  return (
    <DomainSelector
      domainName={convertToValidDomainName(prefill)}
      currency={currency}
      captionsOverrides={captions}
      onSelect={handleSelect}
      onDomainSearchFailure={handleDomainSearchFailure}
      onClose={handleClose}
      onNext={isPaidHostingProduct ? handleNext : null}
      onSearchStart={handleSearchStart}
      onSearchPerformed={handleSearchPerformed}
      onDomainFieldChange={handleDomainFieldChange}
      onSpecialOfferSwitch={onSpecialOfferSwitch}
      additionalPaymentParams={additionalPaymentParams}
      specialOffer={specialOffer}
      extraPrice={extraPriceType}
      activeSubscriptionTerm={activeHostingSubscription?.term}
      partnerName={partnerName}
    />
  );
}

DomainSelectorContainer.propTypes = {
  initiator: PropTypes.string,
  prefill: PropTypes.string,
  extraProducts: PropTypes.arrayOf(PropTypes.string),
  term: PropTypes.string,
  triggerId: PropTypes.string,
  showPaywall: PropTypes.func.isRequired,
  paymentBackUrl: PropTypes.string,
};

DomainSelectorContainer.defaultProps = {
  initiator: '',
  prefill: '',
  extraProducts: [],
  term: '',
  triggerId: null,
  paymentBackUrl: '',
};

export default DomainSelectorContainer;
